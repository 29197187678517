/* global $ */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { showToast } from "../../../utils/toastUtils";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { initializeGoogleAuth } from "../../../utils/googleAuthUtils";
import { getRegistryCredentials, clearRegistryCredentials } from "../../../utils/registryUtils";
import api from "../../../services/api";
import Cookies from 'js-cookie';

const AddShopGiftModal = ({ products, selectedProductId }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [registryDetails, setRegistryDetails] = useState(null);
  const [productImage, setProductImage] = useState("");
  const [itemUrl, setItemUrl] = useState("");
  const [giftTitle, setGiftTitle] = useState("");
  const [price, setPrice] = useState("");
  const [size, setSize] = useState("");
  const [color, setColor] = useState("");
  const [notes, setNotes] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState(1);
  const [isGiftTitleValid, setIsGiftTitleValid] = useState(true);
  const [isPriceValid, setIsPriceValid] = useState(true);
  const [isQtyValid, setIsQtyValid] = useState(true);
  const [showLogin, setShowLogin] = useState(false);
  const [showAddGift, setShowAddGift] = useState(false);
  const [giftAdded, setGiftAdded] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [registryNameId, setRegistryNameId] = useState(null);
  const { accessToken, registryId, fromMobileApp } = getRegistryCredentials();

  useEffect(() => {
    initializeGoogleAuth();
  }, []);

  useEffect(() => {
    if (accessToken) {
      setShowAddGift(true);
    } else {
      setShowLogin(true);
    }
  }, [accessToken]);

  useEffect(() => {
    if (accessToken && selectedProductId && products.length > 0) {
      const selectedProduct = products.find(product => product.id === selectedProductId);
      
      if (selectedProduct) {
        const title = selectedProduct.title || "";
        const price = selectedProduct.price || "";
        const size = selectedProduct.size || "";
        const colors = selectedProduct.colors || [];
        const firstColor = Array.isArray(colors) && colors.length > 0 ? colors[0] : "";
        const url = selectedProduct.url || "";
        const image = selectedProduct.image || "";
    
        setGiftTitle(title);
        setPrice(price);
        setSize(size);
        setColor(firstColor);
        setItemUrl(url);
        setProductImage(image);
        setSelectedProduct(selectedProduct);
      }
    }
  }, [accessToken, selectedProductId, products]);

  const fetchRegistryDetails = async () => {
    try {
      const response = await api.getDashboardDetails(accessToken, registryId);
      setRegistryDetails(response);
      if (response.registries_list && response.registries_list.length > 0) {
        const initialId = registryId || response.registries_list[0].id;
        setRegistryNameId(initialId);
      }
    } catch (error) {
      console.error("Error fetching registry details:", error.message);
    }
  };

  useEffect(() => {
    if (registryId) {
      fetchRegistryDetails(registryId);
    }
    // eslint-disable-next-line
  }, [registryId]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    setUsernameError("");
    setError("");
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordError("");
    setError("");
  };

  const handleRegistryChange = (e) => {
    const updatedRegistryId = e.target.value;
    setRegistryNameId(updatedRegistryId);

    const selectedRegistry = registryDetails.registries_list.find(
      (registry) => registry.id === parseInt(updatedRegistryId)
    );

    if (selectedRegistry) {
      Cookies.set('registryId', updatedRegistryId, { expires: 30, sameSite: 'None', secure: true });
      Cookies.set('registryTypeId', selectedRegistry.registry_type_id, { expires: 30, sameSite: 'None', secure: true });
      Cookies.set('registryTypeName', selectedRegistry.registry_type_name, { expires: 30, sameSite: 'None', secure: true });
      Cookies.set('registryPublic', selectedRegistry.public, { expires: 30, sameSite: 'None', secure: true });

      localStorage.setItem('registryId', updatedRegistryId);
      localStorage.setItem('registryTypeId', selectedRegistry.registry_type_id);
      localStorage.setItem('registryTypeName', selectedRegistry.registry_type_name);
      localStorage.setItem('registryPublic', selectedRegistry.public);
    }
  };

  const navigateToOnboarding = (processStep) => {
    if (fromMobileApp) {
      navigate(`/onboarding-process${processStep ? `?processStep=${processStep}` : ''}`);
    } else {
      const onboardingUrl = `${window.location.origin}/onboarding-process${processStep ? `?processStep=${processStep}` : ''}`;
      window.open(onboardingUrl, "_blank");
    }
    showToast('Please complete your registry setup');
  };

  const handleLogin = async () => {
    let isUsernameValid = true;
    let isPasswordValid = true;

    if(!navigator.onLine){
      showToast(<><i className="la la-warning fs-5"></i>&nbsp;You are offline!</>);
      return;
    }
  
    if (!username.trim()) {
      setUsernameError("Username is required. Please enter a valid username.");
      isUsernameValid = false;
    } else {
      setUsernameError("");
    }
  
    if (!password.trim()) {
      setPasswordError("Password is required. Please enter a valid password.");
      isPasswordValid = false;
    } else {
      setPasswordError("");
    }
  
    if (isUsernameValid && isPasswordValid) {
      try {
        setLoading(true);
        const response = await api.login(username, password);
  
        Cookies.set('accessToken', response.access_token, { expires: 30, sameSite: 'None', secure: true });
        Cookies.set('userId', response.user.id, { expires: 30, sameSite: 'None', secure: true });
        Cookies.set('userName', response.user.first_name, { expires: 30, sameSite: 'None', secure: true });
        Cookies.set('userEmail', response.user.email, { expires: 30, sameSite: 'None', secure: true });

        localStorage.setItem('accessToken', response.access_token);
        localStorage.setItem('userId', response.user.id);
        localStorage.setItem('userName', response.user.first_name);
        localStorage.setItem('userEmail', response.user.email);

        if (response.registry_created === "true") {
          const registryList = response.user.registry;
          const primaryRegistry = registryList[0];
          const registryTypeId = primaryRegistry.registry_type_id;
          const registryTypeName = primaryRegistry.registry_type_name;
  
          Cookies.set('registryTypeId', registryTypeId, { expires: 30, sameSite: 'None', secure: true });
          Cookies.set('registryTypeName', registryTypeName, { expires: 30, sameSite: 'None', secure: true });
          Cookies.set('registryId', primaryRegistry.id, { expires: 30, sameSite: 'None', secure: true });
          Cookies.set('registryPublic', primaryRegistry.public, { expires: 30, sameSite: 'None', secure: true });
  
          localStorage.setItem('registryTypeId', registryTypeId);
          localStorage.setItem('registryTypeName', registryTypeName);
          localStorage.setItem('registryId', primaryRegistry.id);
          localStorage.setItem('registryPublic', primaryRegistry.public);

          if (primaryRegistry.process_complete === "true") {
            setShowAddGift(true);
            setUsername("");
            setPassword("");
            navigate(window.location.pathname);
          } else {
            navigateToOnboarding(2);
          }
        } else {
          navigateToOnboarding();
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.error === "Invalid credentials") {
          setError("Your email or password is incorrect");
        } else {
          setError("An error occurred. Please try again.");
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin();
  };

  const handleGoogleLogin = async (response) => {
    try {
      const key = "login";
      const id_token = response.authentication.accessToken;
      const apiResponse = await api.googleLogin(id_token, key);
      const { access_token, user } = apiResponse.user.original;
      const userType = apiResponse.user.original.userType;
      const registryCreated = apiResponse.user.original.registry_created === 'true';

      if (userType === 'new') {
        GoogleAuth.signOut();
        showToast("You will first need to create an account then log in");
      } else if (userType === 'old') {
        
          Cookies.set('accessToken', access_token, { expires: 30, sameSite: 'None', secure: true });
          Cookies.set('userId', user.id, { expires: 30, sameSite: 'None', secure: true });
          Cookies.set('userName', user.first_name, { expires: 30, sameSite: 'None', secure: true });
          Cookies.set('userEmail', user.email, { expires: 30, sameSite: 'None', secure: true });

          localStorage.setItem('accessToken', access_token);
          localStorage.setItem('userId', user.id);
          localStorage.setItem('userName', user.first_name);
          localStorage.setItem('userEmail', user.email);
          
          if (registryCreated) {
            const registryList = user.registry;
            const primaryRegistry = registryList[0];
            const registryTypeId = primaryRegistry.registry_type_id;
            const registryTypeName = primaryRegistry.registry_type_name;
            const processComplete = primaryRegistry.process_complete === 'true';

            Cookies.set('registryTypeId', registryTypeId, { expires: 30, sameSite: 'None', secure: true });
            Cookies.set('registryTypeName', registryTypeName, { expires: 30, sameSite: 'None', secure: true });
            Cookies.set('registryId', primaryRegistry.id, { expires: 30, sameSite: 'None', secure: true });
            Cookies.set('registryPublic', primaryRegistry.public, { expires: 30, sameSite: 'None', secure: true });
    
            localStorage.setItem('registryTypeId', registryTypeId);
            localStorage.setItem('registryTypeName', registryTypeName);
            localStorage.setItem('registryId', primaryRegistry.id);
            localStorage.setItem('registryPublic', primaryRegistry.public);

            if (processComplete) {
              setShowAddGift(true);
            } else {
              if (fromMobileApp) {
                navigate("/onboarding-process?processStep=2");
              } else {
                window.open("/onboarding-process?processStep=2", "_blank");
              }
              showToast('Please complete your registry setup');  
            }
          } else {
            if (fromMobileApp) {
              navigate('/onboarding-process');
            } else {
              window.open("/onboarding-process", "_blank");
            }
            showToast('Please complete your registry setup');  
          }
        }
    } catch (error) {
        console.error('Error during Google login:', error);
        showToast("Failed to Google login");
    }
  };
 
  const googleLogin = async () => {
    try {
      const response = await GoogleAuth.signIn({
        prompt: "select_account",
      });
      handleGoogleLogin(response);
      
    } catch (error) {
      console.error("Error during Google login:", error);
    }
  };

  const handleAddGift = async () => {
    const staticImageUrl = "https://quickregistry.in/assets/images/no_image_available.png";
    if (!giftTitle || !price || !quantity) {
        showToast("Please enter required gift details");
      if (!giftTitle) {
        setIsGiftTitleValid(false);
      }
      if (!price) {
        setIsPriceValid(false);
      }
      if (!quantity) {
        setIsQtyValid(false);
      }
      return;
    }

    if (quantity < 1 || quantity > 99) {
      setIsQtyValid(false);
      showToast("Please enter valid gift quantity");
      return;
    }

    try {
      setLoading(true);
      const itemData = {
        registry_id: registryId,
        item_category_id: selectedCategory,
        item_name: giftTitle,
        item_url: itemUrl,
        image: productImage || staticImageUrl,
        uid: "10031355",
        item_details: {
          size: size,
          color: color,
          note: notes,
        },
        price: price,
        required_qty: quantity,
        purchased_qty: "0",
        locked_qty: "0",
        added_by: {
          By: "User",
        },
      };

      await api.createRegistryItem(accessToken, registryId, itemData);

      setGiftAdded(true);
      setLoading(false);
      setTimeout(() => {
        $("#ShopGift").modal("hide");
      }, 5000);
    } catch (error) {
      showToast("Unable to add the gift", "error");
      setLoading(false);
    }
  };

  const handleForgotPassword = () => {
    navigate("/forgot-password");
  }

  const handleManageGifts = () => {
    if (fromMobileApp) {
      navigate("/dashboard");
    } else {
      window.open("/dashboard", "_blank");
    }
  }

  const handleSignUpClick = () => {
    if (fromMobileApp) {
      navigate('/signup');
    } else {
      window.open("/signup", "_blank");
    }
  };

  const handleNotYou = async () => {
    try {
      await api.logout(accessToken);
      GoogleAuth.signOut();
      clearRegistryCredentials();
      setShowAddGift(false);
      setGiftAdded(false);
      navigate(window.location.pathname);
    } catch (error) {
      console.error("Logout error:", error.message);
    }
  };

  const handleClose = () => {
    setGiftAdded(false);
    setIsGiftTitleValid(true);
    setIsPriceValid(true);
    setIsQtyValid(true);
    $("#ShopGift").modal("hide");
  }

  return (
    <div className="modal fade" id="ShopGift" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className={`modal-dialog add-gifts-popup ${!accessToken ? 'add-gifts-popup-shop' : ''}`}>
        <div className="modal-content">
          <div className="modal-header modal-header-btn">
            <div className="modal-logo adjust-logo">
              <Link className="navbar-logo" to={fromMobileApp ? "/dashboard" : ""}>
                <img src="/assets/images/logo-white.png" alt="" loading="lazy" />
              </Link>
            </div>
            <div className="modal-user">
            {(showAddGift || giftAdded) && accessToken ? (
              <>
                <p>
                  {registryDetails?.user_first_name || registryDetails?.user_last_name
                    ? `Hi, ${registryDetails?.user_first_name} ${registryDetails?.user_last_name}`
                    : 'Hi'}
                  {registryDetails?.user_answers?.[1]?.question_answer && 
                    ` & ${registryDetails?.user_answers[1].question_answer}`}!
                  <Link onClick={handleNotYou}> Not You?</Link>
                </p>
              </>
            ) : (
              <>
                <p>New to QuickRegistry? <Link to="#" onClick={handleSignUpClick}>Sign Up</Link></p>
              </>
            )}
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
            </div>
          </div>
          {giftAdded && accessToken ? (
            <div className="modal-body login-box row">
              <div className="col-lg-6 col-sm-6">
                <div className="product-image">
                  <div className="product-imgs">
                    <div className="img-display mt-5">
                      <div className="img-showcase">
                        <img src={productImage} alt="" className="img-fluid d-block mx-auto" style={{ height: '338px', objectFit: 'contain' }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6">
                <div className="product-form manage-gift">
                  <div className="user-box">
                    <p className="fs-4 mb-5 text-center">Your Gift has been added!</p>
                  </div>
                  <div className="adjust-manage-btn">
                    <button type="button" className="form-btn mb-4" onClick={handleManageGifts}>
                      MANAGE MY GIFTS
                    </button>
                    <button type="button" className="form-btn cancel-btn" onClick={handleClose}>
                      CONTINUE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : selectedProduct && showAddGift ? (
            <div className="modal-body login-box row">
              <div className="col-lg-6 col-sm-6">
                <div className="product-image">
                  <div className="product-imgs">
                    <div className="img-display mb-3">
                      <div className="img-showcase">
                        {!fromMobileApp ? (
                          <img
                            src={productImage}
                            alt=""
                            className="img-fluid d-block mx-auto"
                            style={{ height: "338px", objectFit: "contain" }}
                          />
                        ) : (
                          <>
                            {productImage ? (
                              <img
                                src={productImage}
                                alt=""
                                className="img-fluid d-block mx-auto"
                                style={{ height: "338px", objectFit: "contain" }}
                              />
                            ) : (
                              <img
                                src="/assets/images/no_image_available.png"
                                alt=""
                                className="img-fluid d-block mx-auto"
                                style={{ height: "338px", objectFit: "contain" }}
                              />
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <form className="mt-4">
                    <div className="user-box">
                      <textarea
                        id="note"
                        name="Note"
                        placeholder="Notes"
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                      />
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6">
                <div className="product-form">
                  <form>
                    {registryDetails && registryDetails.registries_list && registryDetails.registries_list.length === 1 ? (
                      <div className="user-box mt-3">
                        <input type="text" disabled value={registryDetails.registries_list[0].name} />
                        <label>Add this to</label>
                      </div>
                    ) : (
                      <div className="user-registry-list mt-1 mb-3">
                        <label className="mb-2 custom-label"> Add this to </label>
                        <div className="select-wrapper mb-4">
                          <select
                            name="RegistryName"
                            id="registryListSelect"
                            className="form-control"
                            value={registryNameId}
                            onChange={handleRegistryChange}
                          >
                            {registryDetails && registryDetails.registries_list && registryDetails.registries_list.map((registry) => (
                              <option key={registry.id} value={registry.id}>
                                {registry.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}
                    <div className="user-box">
                      <input
                        type="text"
                        name="Gift Title"
                        value={giftTitle}
                        onChange={(e) => {
                          setGiftTitle(e.target.value);
                          setIsGiftTitleValid(true);
                        }}
                        className={`form-control ${!isGiftTitleValid ? "is-invalid" : ""}`}
                        required
                      />
                      <label>Gift Title</label>
                    </div>
                    <div className="user-box product-popup-price">
                      <div className="product-column">
                        <input
                          type="text"
                          name="Price"
                          value={`₹ ${price}`}
                          onChange={(e) => {
                            const numericValue = e.target.value.replace(/₹/g, "").trim();
                            setPrice(numericValue);
                            setIsPriceValid(true);
                          }}
                          className={`form-control ${!isPriceValid ? "is-invalid" : ""}`}
                          required
                        />
                        <label>Price</label>
                      </div>
                      <div className="product-column">
                        <div className="select-wrapper">
                          <select name="Currency" id="currencySelect" className="form-control">
                            <option value="Inr">INR</option>
                          </select>
                        </div>
                      </div>
                      <div className="product-column">
                        <input
                          type="number"
                          name="Qty"
                          value={quantity}
                          onChange={(e) => {
                            setQuantity(e.target.value);
                            setIsQtyValid(true);
                          }}
                          className={`form-control ${!isQtyValid ? "is-invalid" : ""}`}
                          min={1}
                          max={99}
                          required
                        />
                        <label>Qty</label>
                      </div>
                    </div>
                    <div className="user-box product-field">
                      <div className="product-column">
                        <input
                          type="text"
                          name="Size"
                          value={size}
                          onChange={(e) => setSize(e.target.value)}
                          required
                        />
                        <label>Size</label>
                      </div>
                      <div className="product-column">
                        <input
                          type="text"
                          name="Color"
                          value={color}
                          onChange={(e) => setColor(e.target.value)}
                          required
                        />
                        <label>Color</label>
                      </div>
                    </div>
                    <div className="mb-3">
                      <label className="mb-2"> Category </label>
                      <div className="select-wrapper">
                        <select
                          name="Category"
                          id="categorySelect"
                          className="form-control"
                          value={selectedCategory}
                          onChange={(e) => setSelectedCategory(e.target.value)}
                        >
                          {registryDetails &&
                            registryDetails.item_categories &&
                            registryDetails.item_categories.map((category) => (
                              <option key={category.id} value={category.id}>
                                {category.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </form>
                  <button type="button" className="btn btn-primary form-btn main-btn" onClick={handleAddGift} disabled={loading}>
                    {loading ? "ADDING GIFT..." : "ADD GIFT"}
                  </button>
                </div>
              </div>
            </div>
          ) : showLogin ? (
            <div className="modal-body login-box row">
              <div className="col-lg-12 col-sm-12">
                <div className="login-form">
                  <form onSubmit={handleSubmit}>
                    <div className="user-box">
                      <p className="text-center text-dark fs-5 mt-3 mb-4">Please log in to add this gift to your registry</p>
                    </div>
                    {error && (
                        <p className="validation-login-main text-danger text-center mt-5">{error}</p>
                    )}
                    <div className="user-box user-box-add-gift">
                      <input
                        type="text"
                        name="username"
                        value={username}
                        onChange={handleUsernameChange}
                        onKeyPress={(e) => e.key === 'Enter' && handleSubmit(e)}
                        className={`form-control ${usernameError ? "is-invalid" : ""}`}
                        required
                      />
                      <label>Email</label>
                      <p className="validation invalid-feedback">{usernameError}</p>
                    </div>
                    <div className="user-box user-box-add-gift">
                      <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={password}
                        onChange={handlePasswordChange}
                        onKeyPress={(e) => e.key === 'Enter' && handleSubmit(e)}
                        className={`form-control ${passwordError ? "is-invalid" : ""}`}
                        required
                      />
                      <label>Password</label>
                      <Link
                        className="password-toggle-icon login-picon end-0 me-3"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? (
                          <i className="las la-eye-slash fs-4"></i>
                        ) : (
                          <i className="las la-eye fs-4"></i>
                        )}
                      </Link>
                      <p className="validation invalid-feedback">{passwordError}</p>
                    </div>

                    <div className="user-box float-end option-adjust">
                      <Link onClick={handleForgotPassword} >Forgot Password?</Link>
                    </div>
                    <button type="submit" className="visually-hidden">Submit</button>
                  </form>
                </div>
                <div className="modal-footer mt-5">
                  <button type="button" onClick={handleLogin} className="btn btn-primary form-btn add-gift-login-btn mx-auto my-3" disabled={loading}>
                  {loading ? (
                      <>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> &nbsp;Logging in...
                      </>
                    ) : (
                      "Login"
                    )}
                  </button>
                  <div className="user-box or">
                    <p>or</p>
                  </div>
                  <div className="user-box social-adjust">
                    <div className="social-btn">
                      <Link to="#" className="google-btn" onClick={() => googleLogin()}>
                        <img src="/assets/images/icon/google-icon.svg" alt="Google icon" className="me-2" style={{ width: '22px', height: '22px' }} />
                        Sign in with Google
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AddShopGiftModal;