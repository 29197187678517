import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { showToast } from '../../utils/toastUtils';
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { initializeGoogleAuth } from "../../utils/googleAuthUtils";
import { getRegistryCredentials } from "../../utils/registryUtils";
import api from "../../services/api";
import Cookies from 'js-cookie';

const MobileLogin = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(true);
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [termsError, setTermsError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { fromiOSDevice } = getRegistryCredentials();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    initializeGoogleAuth();
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleForgotPassword = () => {
    navigate("/forgot-password");
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    setUsernameError("");
    setError("");
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordError("");
    setError("");
  };

  const handleTermsChange = (e) => {
    setTermsAccepted(e.target.checked);
    setTermsError("");
  };

  const handleLogin = async (e) => {
    e.preventDefault();
  
    let isUsernameValid = true;
    let isPasswordValid = true;
  
    if (!navigator.onLine) {
      showToast(
        <>
          <i className="la la-warning i-icon"></i>&nbsp;You are offline!
        </>
      );
      return;
    }
  
    if (!username.trim()) {
      setUsernameError("Username is required. Please enter a valid username.");
      isUsernameValid = false;
    } else {
      setUsernameError("");
    }
  
    if (!password.trim()) {
      setPasswordError("Password is required. Please enter a valid password.");
      isPasswordValid = false;
    } else {
      setPasswordError("");
    }
  
    if (!termsAccepted) {
      setTermsError("You must accept the terms and conditions to continue.");
      return;
    }
  
    if (isUsernameValid && isPasswordValid) {
      try {
        setLoading(true);
        const response = await api.login(username, password);
  
        Cookies.set('accessToken', response.access_token, { expires: 30, sameSite: 'None', secure: true });
        Cookies.set('userId', response.user.id, { expires: 30, sameSite: 'None', secure: true });
        Cookies.set('userName', response.user.first_name, { expires: 30, sameSite: 'None', secure: true });
        Cookies.set('userEmail', response.user.email, { expires: 30, sameSite: 'None', secure: true });
  
        localStorage.setItem('accessToken', response.access_token);
        localStorage.setItem('userId', response.user.id);
        localStorage.setItem('userName', response.user.first_name);
        localStorage.setItem('userEmail', response.user.email);
  
        if (response.registry_created === "true") {
          const registryList = response.user.registry;
          const primaryRegistry = registryList[0];
          const registryTypeId = primaryRegistry.registry_type_id;
          const registryTypeName = primaryRegistry.registry_type_name;
  
          Cookies.set('registryTypeId', registryTypeId, { expires: 30, sameSite: 'None', secure: true });
          Cookies.set('registryTypeName', registryTypeName, { expires: 30, sameSite: 'None', secure: true });
          Cookies.set('registryId', primaryRegistry.id, { expires: 30, sameSite: 'None', secure: true });
          Cookies.set('registryPublic', primaryRegistry.public, { expires: 30, sameSite: 'None', secure: true });
  
          localStorage.setItem('registryTypeId', registryTypeId);
          localStorage.setItem('registryTypeName', registryTypeName);
          localStorage.setItem('registryId', primaryRegistry.id);
          localStorage.setItem('registryPublic', primaryRegistry.public);
  
          if (primaryRegistry.process_complete === "false") {
            navigate("/onboarding-process?processStep=2");
            showToast("Please complete your registry setup");
          } else {
            if (registryList.length > 1) {
              navigate("/all-registry");
            } else {
              navigate("/dashboard");
            }
          }
        } else {
          showToast("Please complete your registry setup");
          navigate("/onboarding-process");
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.error === "Invalid credentials") {
          setError("Your email or password is incorrect");
        } else {
          setError("An error occurred. Please try again.");
        }
      } finally {
        setLoading(false);
      }
    }
  };  

  const handleGoogleLogin = async (response) => {
    try {
        const key = "login";
        const id_token = response.authentication.accessToken;
        const apiResponse = await api.googleLogin(id_token, key);
        const { access_token, user } = apiResponse.user.original;
        const userType = apiResponse.user.original.userType;
        const registryCreated = apiResponse.user.original.registry_created === 'true';

        if (userType === 'new') {
          GoogleAuth.signOut();
          showToast("You will first need to create an account then log in");
        } else if (userType === 'old') {
          
            Cookies.set('accessToken', access_token, { expires: 30, sameSite: 'None', secure: true });
            Cookies.set('userId', user.id, { expires: 30, sameSite: 'None', secure: true });
            Cookies.set('userName', user.first_name, { expires: 30, sameSite: 'None', secure: true });
            Cookies.set('userEmail', user.email, { expires: 30, sameSite: 'None', secure: true });

            localStorage.setItem('accessToken', access_token);
            localStorage.setItem('userId', user.id);
            localStorage.setItem('userName', user.first_name);
            localStorage.setItem('userEmail', user.email);
            
            if (registryCreated) {
              const registryList = user.registry;
              const primaryRegistry = registryList[0];
              const registryTypeId = primaryRegistry.registry_type_id;
              const registryTypeName = primaryRegistry.registry_type_name;
              const processComplete = primaryRegistry.process_complete === 'true';

              Cookies.set('registryTypeId', registryTypeId, { expires: 30, sameSite: 'None', secure: true });
              Cookies.set('registryTypeName', registryTypeName, { expires: 30, sameSite: 'None', secure: true });
              Cookies.set('registryId', primaryRegistry.id, { expires: 30, sameSite: 'None', secure: true });
              Cookies.set('registryPublic', primaryRegistry.public, { expires: 30, sameSite: 'None', secure: true });
      
              localStorage.setItem('registryTypeId', registryTypeId);
              localStorage.setItem('registryTypeName', registryTypeName);
              localStorage.setItem('registryId', primaryRegistry.id);
              localStorage.setItem('registryPublic', primaryRegistry.public);

              if (processComplete) {
                if (registryList.length > 1) {
                  navigate("/all-registry");
                } else if (registryList.length === 1) {
                  navigate("/dashboard");
                }
              } else {
                navigate("/onboarding-process?processStep=2");
                showToast("Please complete your registry setup");
              }
            } else {
              navigate("/onboarding-process");
              showToast("Please complete your registry setup");
            }
        }
    } catch (error) {
        console.error('Error during Google login:', error);
        showToast("Failed to Google login");
    }
  };

const googleLogin = async () => {
  try {
    const response = await GoogleAuth.signIn({
      prompt: "select_account",
    });
    handleGoogleLogin(response);
    
  } catch (error) {
    console.error("Error during Google login:", error);
  }
};

  return (
    <div className="wrapper animsition">
      <div className="wrapper-inner">
        <main className="main">
          <article>
            <section className="onboarding-process">
              <div className="container">
                <div className="row">
                  <div className="process-box">
                    <div className="col-md-12 text-center">
                    {fromiOSDevice && <div className="spacing-mid"></div>}
                      <div className="spacing-mid"></div>
                      <Link to="/" className="navbar-logo">
                        <img
                          src="assets/images/logo.png"
                          alt="logo"
                          loading="lazy"
                        />
                      </Link>
                      <div className="spacing-mid"></div>
                    </div>
                    <div className="col-md-12 text-center">
                      <h1>Login to your Registry</h1>
                      <div className="spacing-mid"></div>
                    </div>
                    <div className="col-md-12 mt-4">
                      <form className="login-form" noValidate>
                        {error && (
                            <p className="validation-login-main text-danger text-center">{error}</p>
                        )}
                        <div className="row">
                          <div className="col-md-12 mb-2">
                            <div className="user-box">
                              <input
                                type="text"
                                name="email"
                                value={username}
                                onChange={handleUsernameChange}
                                onKeyPress={(e) => e.key === 'Enter' && handleLogin(e)}
                                className={`form-control ${usernameError ? "is-invalid" : ""}`}
                                autoComplete="offAuto"
                                required
                              />
                              <p className="validation invalid-feedback"> {usernameError} </p>
                              <label>Email Address</label>
                            </div>
                          </div>
                          <div className="col-md-12 mb-2">
                            <div className="user-box">
                              <input
                                type={showPassword ? "text" : "password"}
                                name="password"
                                value={password}
                                onChange={handlePasswordChange}
                                onKeyPress={(e) => e.key === 'Enter' && handleLogin(e)}
                                className={`mb-2 form-control ${passwordError ? "is-invalid" : ""}`}
                                maxLength={20}
                                required
                              />
                              <label>Password</label>
                              <Link
                                className="password-toggle-icon position-absolute end-0 bottom-50 me-4"
                                onClick={togglePasswordVisibility}
                              >
                                {showPassword ? (
                                  <i className="las la-eye-slash fs-4"></i>
                                ) : (
                                  <i className="las la-eye fs-4"></i>
                                )}
                              </Link>
                              <p className="validation-signup invalid-feedback"> {passwordError} </p>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="user-box float-end">
                                <Link data-bs-dismiss="modal" onClick={handleForgotPassword}>
                                Forgot Password?
                                </Link>
                            </div>
                          </div>
                          <div className="col-md-12 mt-3">
                            <div className="user-box">
                              <div className="checkbox checkbox-container checkbox-size">
                                <input
                                  type="checkbox"
                                  id="termsCheckbox"
                                  name="termsAccepted"
                                  checked={termsAccepted}
                                  onChange={handleTermsChange}
                                  onKeyPress={(e) => e.key === 'Enter' && handleLogin(e)}
                                  required
                                />
                                <label
                                  className="checkbox-text mobile-checkbox-text"
                                >
                                  I accept the website's&nbsp;
                                  <Link to="/terms&conditions" onClick={() => window.scrollTo(0, 0)}>Terms & Conditions</Link> and&nbsp;
                                  <Link to="/privacy-policy" onClick={() => window.scrollTo(0, 0)}>Privacy Policy</Link>
                                </label>
                              </div>
                              <p className="validation-signup text-danger">{termsError}</p>
                            </div>
                          </div>
                          <div className="col-md-12 mt-4">
                            <div className="user-box">
                            <button
                              className="btn btn-primary form-btn main-btn"
                              onClick={handleLogin}
                              disabled={loading}
                            >
                              {loading ? (
                                <>
                                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> &nbsp;Logging in...
                                </>
                              ) : (
                                "Login"
                              )}
                            </button>
                            </div>
                            <div className="user-box or text-center">
                              <p>or</p>
                            </div>
                            <div className="user-box text-center">
                              <div className="social-btn">
                                <Link to="#" className="google-btn" onClick={googleLogin}>
                                  <img src="/assets/images/icon/google-icon.svg" alt="Google icon" className="me-2" style={{ width: '22px', height: '22px' }} />
                                  Sign in with Google
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      <button type="submit" className="visually-hidden">Submit</button>
                      </form>
                      <div className="spacing"></div>
                    </div>
                    <div className="col-md-12">
                      <div className={`back-btn-text ${fromiOSDevice && 'ios-area'}`}>
                        <p>
                          <Link to="/" className="back-button">
                            <i className="la la-angle-left"></i> Back
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className={`login-text ${fromiOSDevice && 'ios-area'}`}>
                        <p>
                          New to QuickRegistry?&nbsp;
                          <Link
                            to="/signup"
                            className="login-already"
                          >
                            SignUp
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row"></div>
              </div>
            </section>
          </article>
        </main>
      </div>
    </div>
  );
};

export default MobileLogin;
