  export const ageImages = [
    { src: "/assets/images/shop/0-6mths.webp", alt: "0 to 6 Months", link: "/categories/all?subcategory=0-to-6-months" },
    { src: "/assets/images/shop/6mths-1yr.webp", alt: "6 Months to 1 Year", link: "/categories/all?subcategory=6-months-to-1-year" },
    { src: "/assets/images/shop/1-2yrs.webp", alt: "1 to 2 Years", link: "/categories/all?subcategory=1-to-2-years" },
    { src: "/assets/images/shop/2-3yrs.webp", alt: "2 to 3 Years", link: "/categories/all?subcategory=2-to-3-years" },
    { src: "/assets/images/shop/3-5yrs.webp", alt: "3 to 5 Years", link: "/categories/all?subcategory=3-to-5-years" },
    { src: "/assets/images/shop/5-7yrs.webp", alt: "5 to 7 Years", link: "/categories/all?subcategory=5-to-7-years" },
    { src: "/assets/images/shop/7-10yrs.webp", alt: "7 to 10 Years", link: "/categories/all?subcategory=7-to-10-years" },
    { src: "/assets/images/shop/10plusyrs.webp", alt: "10 Years and Over", link: "/categories/all?subcategory=10-years-and-over" }
  ];
  
  export const brandImages = [
    { src: "/assets/images/amazon.png", alt: "Amazon", link: "/categories/amazon" },
    { src: "/assets/images/r-for-rabbit.png", alt: "R-for-rabbit", link: "/categories/r-for-rabbit" },
    { src: "/assets/images/amazon.png", alt: "Amazon", link: "/categories/amazon" },
    { src: "/assets/images/r-for-rabbit.png", alt: "R-for-rabbit", link: "/categories/r-for-rabbit" },
    // { src: "/assets/images/firstcry.png", alt: "Firstcry", link: "/categories/firstcry" },
    // { src: "/assets/images/myntra.png", alt: "Myntra", link: "/categories/myntra" },
    // { src: "/assets/images/little-muffet.png", alt: "Little Muffet", link: "/categories/little-muffet" },
    // { src: "/assets/images/mumkins.png", alt: "Mumkins", link: "/categories/mumkins" },
    // { src: "/assets/images/hopscotch.png", alt: "Hopscotch", link: "/categories/hopscotch" },
    // { src: "/assets/images/mothercare.png", alt: "Mothercare", link: "/categories/mothercare" },
    // { src: "/assets/images/carters.png", alt: "Carters", link: "/categories/carters" },
    // { src: "/assets/images/h&m.png", alt: "H&M", link: "/categories/h&m" },
    // { src: "/assets/images/zara.png", alt: "Zara", link: "/categories/zara" },
    // { src: "/assets/images/hamleys.png", alt: "Hamleys", link: "/categories/hamleys" },
    // { src: "/assets/images/ajio.png", alt: "Ajio", link: "/categories/ajio" }
  ];
  
  export const categoryImages = [
    { src: "/assets/images/shop/bath.webp", alt: "Bath", link: "/categories/all?subcategory=bath" },
    { src: "/assets/images/shop/changing.webp", alt: "Changing", link: "/categories/all?subcategory=changing" },
    { src: "/assets/images/shop/clothing.webp", alt: "Clothing", link: "/categories/all?subcategory=clothing" },
    { src: "/assets/images/shop/feeding.webp", alt: "Feeding", link: "/categories/all?subcategory=feeding" },
    { src: "/assets/images/shop/health.webp", alt: "Health", link: "/categories/all?subcategory=health" },
    { src: "/assets/images/shop/new-parents.webp", alt: "New Parents", link: "/categories/all?subcategory=new-parents" },
    { src: "/assets/images/shop/nursery.webp", alt: "Nursery", link: "/categories/all?subcategory=nursery" },
    { src: "/assets/images/shop/playing.webp", alt: "Playing", link: "/categories/all?subcategory=playing" },
    { src: "/assets/images/shop/pregnancy.webp", alt: "Pregnancy", link: "/categories/all?subcategory=pregnancy" },
    { src: "/assets/images/shop/traveling.webp", alt: "Travel", link: "/categories/all?subcategory=travel" }
  ];
  
  export const wishlistsImages = [
    { src: "/assets/images/shop/adding-to-the-brood.webp", alt: "Adding to the Brood", link: "/categories/all?subcategory=adding-to-the-brood" },
    { src: "/assets/images/shop/books.webp", alt: "Books", link: "/categories/all?subcategory=books" },
    { src: "/assets/images/shop/christening.webp", alt: "Christening", link: "/categories/all?subcategory=christening" },
    { src: "/assets/images/shop/gadget-loving-mum.webp", alt: "Gadget Lovers", link: "/categories/all?subcategory=gadget-lovers" },
    { src: "/assets/images/shop/gifts-for-a-1-yr-old.webp", alt: "Gifts for a 1 Year Old", link: "/categories/all?subcategory=gifts-for-a-1-year-old" },
    { src: "/assets/images/shop/gifts-for-a-2-yr-old.webp", alt: "Gifts for a 2 Year Old", link: "/categories/all?subcategory=gifts-for-a-2-year-old" },
    { src: "/assets/images/shop/gifts-for-a-3-yr-old.webp", alt: "Gifts for a 3 Year Old", link: "/categories/all?subcategory=gifts-for-a-3-year-old" },
    { src: "/assets/images/shop/gifts-for-a-4-yr-old.webp", alt: "Gifts for a 4 Year Old", link: "/categories/all?subcategory=gifts-for-a-4-year-old" },
    { src: "/assets/images/shop/gifts-for-a-5-yr-old.webp", alt: "Gifts for a 5 Year Old", link: "/categories/all?subcategory=gifts-for-a-5-year-old" },
    { src: "/assets/images/shop/gifts-for-a-baby.webp", alt: "Gifts for a Baby", link: "/categories/all?subcategory=gifts-for-a-baby" },
    { src: "/assets/images/shop/hospital-bag-list.webp", alt: "Hospital Bag", link: "/categories/all?subcategory=hospital-bag" },
    { src: "/assets/images/shop/personalised.webp", alt: "Personalised", link: "/categories/all?subcategory=personalised" },
    { src: "/assets/images/shop/small-business.webp", alt: "Small Businesses", link: "/categories/all?subcategory=small-business" },
    { src: "/assets/images/shop/something-for-mum.webp", alt: "Something for Mum", link: "/categories/all?subcategory=something-for-mum" },
    { src: "/assets/images/shop/something-special.webp", alt: "Something Special", link: "/categories/all?subcategory=something-special" },
    { src: "/assets/images/shop/subscriptions.webp", alt: "Subscriptions", link: "/categories/all?subcategory=subscriptions" },
    { src: "/assets/images/shop/timeless-baby-items.webp", alt: "Timeless Baby Items", link: "/categories/all?subcategory=timeless-baby-items" }
  ];  

  export const coverImages = [
    { src: "/assets/images/shop/cover/0-6-months.jpg", alt: "0 to 6 Months"},
    { src: "/assets/images/shop/cover/6-months-1-year.webp", alt: "6 Months to 1 Year"},
    { src: "/assets/images/shop/cover/1-2-years.webp", alt: "1 to 2 Years"},
    { src: "/assets/images/shop/cover/2-3-years.webp", alt: "2 to 3 Years"},
    { src: "/assets/images/shop/cover/3-5-years.webp", alt: "3 to 5 Years"},
    { src: "/assets/images/shop/cover/5-7-years.webp", alt: "5 to 7 Years"},
    { src: "/assets/images/shop/cover/7-10-years.webp", alt: "7 to 10 Years"},
    { src: "/assets/images/shop/cover/10-plus-years.webp", alt: "10 Years and Over"},
    { src: "/assets/images/shop/cover/firstcry.png", alt: "Firstcry"},
    { src: "/assets/images/shop/cover/little-muffet.webp", alt: "Little Muffet"},
    { src: "/assets/images/shop/cover/mumkins.png", alt: "Mumkins"},
    // { src: "/assets/images/shop/cover/hopscotch.jpg", alt: "Hopscotch"},
    { src: "/assets/images/shop/cover/r-for-rabbit.jpg", alt: "R-for-rabbit"},
    { src: "/assets/images/shop/cover/mothercare.png", alt: "Mothercare"},
    { src: "/assets/images/shop/cover/carters.png", alt: "Carters"},
    { src: "/assets/images/shop/cover/h&m.png", alt: "H&M"},
    { src: "/assets/images/shop/cover/zara.jpg", alt: "Zara"},
    { src: "/assets/images/shop/cover/hamleys.png", alt: "Hamleys"},
    { src: "/assets/images/shop/cover/amazon.jpeg", alt: "Amazon"},
    { src: "/assets/images/shop/cover/myntra.jpg", alt: "Myntra"},
    { src: "/assets/images/shop/cover/ajio.png", alt: "Ajio"},
    { src: "/assets/images/shop/cover/bath.webp", alt: "Bath"},
    { src: "/assets/images/shop/cover/changing.webp", alt: "Changing"},
    { src: "/assets/images/shop/cover/clothing.webp", alt: "Clothing"},
    { src: "/assets/images/shop/cover/feeding.webp", alt: "Feeding"},
    { src: "/assets/images/shop/cover/health.webp", alt: "Health"},
    { src: "/assets/images/shop/cover/new-parents.webp", alt: "New Parents"},
    { src: "/assets/images/shop/cover/nursery.webp", alt: "Nursery"},
    { src: "/assets/images/shop/cover/playing.webp", alt: "Playing"},
    { src: "/assets/images/shop/cover/pregnancy.webp", alt: "Pregnancy"},
    { src: "/assets/images/shop/cover/travel.webp", alt: "Travel"},
    { src: "/assets/images/shop/cover/adding-to-the-brood.webp", alt: "Adding to the Brood"},
    { src: "/assets/images/shop/cover/books.webp", alt: "Books"},
    { src: "/assets/images/shop/cover/christening.webp", alt: "Christening"},
    { src: "/assets/images/shop/cover/gadget-lovers.webp", alt: "Gadget Lovers"},
    { src: "/assets/images/shop/cover/gifts-for-a-1-year-old.webp", alt: "Gifts for a 1 Year Old"},
    { src: "/assets/images/shop/cover/gifts-for-a-2-year-old.webp", alt: "Gifts for a 2 Year Old"},
    { src: "/assets/images/shop/cover/gifts-for-a-3-year-old.webp", alt: "Gifts for a 3 Year Old"},
    { src: "/assets/images/shop/cover/gifts-for-a-4-year-old.webp", alt: "Gifts for a 4 Year Old"},
    { src: "/assets/images/shop/cover/gifts-for-a-5-year-old.webp", alt: "Gifts for a 5 Year Old"},
    { src: "/assets/images/shop/cover/gifts-for-a-baby.webp", alt: "Gifts for a Baby"},
    { src: "/assets/images/shop/cover/hospital-bag.webp", alt: "Hospital Bag"},
    { src: "/assets/images/shop/cover/personalised.webp", alt: "Personalised"},
    { src: "/assets/images/shop/cover/small-businesses.webp", alt: "Small Businesses"},
    { src: "/assets/images/shop/cover/something-for-mum.webp", alt: "Something for Mum"},
    { src: "/assets/images/shop/cover/something-special.webp", alt: "Something Special"},
    { src: "/assets/images/shop/cover/subscriptions.webp", alt: "Subscriptions"},
    { src: "/assets/images/shop/cover/timeless-baby-items.webp", alt: "Timeless Baby Items"}
  ];

  export const shopImages = () => {
    return [
      ...ageImages,
      ...brandImages,
      ...categoryImages,
      ...wishlistsImages,
    ];
  };
  