// /* global $ */
import React, { useEffect, useState } from 'react';
import api from '../../../services/api';

const ViewOnlineModal = ({ productId, currentPage }) => {
  const [loading, setLoading] = useState(false);
  const [productDetails, setProductDetails] = useState(null);
  const brand = window.location.pathname.split("/")[2];

  useEffect(() => {
    const fetchShopProductDetails = async () => {
      setLoading(true);
      try {
        const response = await api.getShopProductDetails(brand, productId, currentPage);
        setProductDetails(response);
      } catch (error) {
        console.error('Error fetching product details:', error);
      } finally {
        setLoading(false);
      }
    };

    if (productId && brand) {
      fetchShopProductDetails();
    }
  }, [productId, currentPage, brand]);

  // const handleAddToWishlist = (productId) => {
  //   $("#view-online-modal").modal("hide");
  //   $("#ShopGift").modal("show");
  // };

  const handleViewOnline = () => {
    if (productDetails && productDetails.url) {
      window.open(productDetails.url, '_blank');
    }
  };

  return (
    <div className="modal fade" id="view-online-modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog common-popup buy-mulitple-popup">
        <div className="modal-content">
          <div className="modal-header">
            <p>PRODUCT DETAILS</p>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body row">
            <div className="col-lg-12">
              <div className="product-container">
                {loading ? (
                  <div className="spinner-container shop-modal-spinner">
                    <div className="spinner-grow" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : productDetails ? (
                  <>
                    {productDetails.images.length > 1 ? (
                      <div id="carouselShopIndicators" className="carousel slide" data-bs-ride="carousel" data-bs-interval="2000">
                        <div className="carousel-indicators">
                          {productDetails.images.map((image, index) => (
                            <button
                              key={index}
                              type="button"
                              data-bs-target="#carouselShopIndicators"
                              data-bs-slide-to={index}
                              className={index === 0 ? "active" : ""}
                              aria-current={index === 0 ? "true" : "false"}
                              aria-label={`Slide ${index + 1}`}
                            ></button>
                          ))}
                        </div>
                        <div className="carousel-inner carousel-product-image">
                          {productDetails.images.map((image, index) => (
                            <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`}>
                              <img src={image} className="d-block w-60 mx-auto" alt={productDetails.title} style={{ height: "280px", objectFit: "contain" }} />
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div className="carousel-inner carousel-product-image">
                        <div className="carousel-item active">
                          <img src={productDetails.images[0]} className="d-block w-60 mx-auto" alt={productDetails.title} style={{ height: "280px", objectFit: "contain" }} />
                        </div>
                      </div>
                    )}
                    <div className="product-view-container">
                      <h5 className="product-view-name clamp-three-lines">{productDetails.title}</h5>
                      <p className="product-view-price">₹ {productDetails.price}</p>
                    </div>
                    <div className="user-detials-btn text-center mb-2">
                      {/* <button type="button" className="shop-modal-btn common-btn active-btn mb-3" onClick={() => handleAddToWishlist(productDetails.id)}>Add to Wishlist</button> */}
                      <button type="button" className="shop-modal-btn shop-view-btn common-btn" onClick={handleViewOnline}>View Online</button>
                    </div>
                  </>
                ) : (
                  <div className="no-info-avilable">
                    <h4>No information available for this product</h4>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewOnlineModal;