import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import Navbar from "../../components/common/Navbar";
import { ageImages, brandImages, categoryImages, wishlistsImages } from "../../utils/imagesUtils";

const ShowAllPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");

  let images = [];
  let heading = "";

  switch (type) {
    case "age":
      images = ageImages;
      heading = "Age Ranges";
      break;
    case "brand":
      images = brandImages;
      heading = "Brands and Retailers";
      break;
    case "category":
      images = categoryImages;
      heading = "Category";
      break;
    case "wishlists":
      images = wishlistsImages;
      heading = "Example Wishlists";
      break;
    default:
      images = ageImages;
      heading = "Age Ranges";
      break;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="wrapper animsition">
      <div className="wrapper-inner">
        <header className="header">
          <Header />
          <Navbar />
        </header>
        <main className="main">
          <article>
            <section>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="show-all-items">
                      <div className="browse-heading">
                        <h2>{heading}</h2>
                      </div>
                      <div className={`img-container ${type === "brand" && "logo-img"}`}>
                        <div className="row">
                            {images.map((image, index) => (
                            <div key={index} className="col-lg-3 col-md-4 col-6 mb-4">
                              <div className={`image-item ${type === "brand" && "image-brand"}`}>
                                <Link to={image.link}>
                                    <img src={image.src} alt={image.alt} className="img-fluid" />
                                    {type !== "brand" && <p className="full-caption">{image.alt}</p>}
                                </Link>
                                </div>
                            </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </article>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default ShowAllPage;